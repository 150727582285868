import React from 'react'
import {graphql} from 'gatsby'
import get from 'lodash/get'
import { CalculatorPageLayout } from '../components/layouts'

export default (props) => {
    const calculator = {
        ...get(props, 'data.contentfulWidgetCalculator'),
        ...get(props, 'data.file.fields'),
    }
    let sitemap = get(props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
    return (
        <CalculatorPageLayout calculator={calculator} sitemap={sitemap} />
    )
}

export const pageQuery = graphql`
query CalculatorBySlug($slug: String!) {
    contentfulWidgetCalculator(slug: { eq: $slug }) {
        name
        information {
            childContentfulRichText {
                internal {
                    content
                }
                html
            }
        }
        disclaimer {
            content {
                childContentfulRichText {
                    internal {
                        content
                    }
                    html
                }
            }
        }
        metaDescription {
            metaDescription
        }
        searchable
    }
    file(name: {eq: $slug}) {
        fields {
            html
        }
    }
    allContentfulWidgetSitemap(filter:{title: {ne: "Don't delete - sitemap"}}) {
        totalCount
        edges {
            node {
                contentful_id
                userSection
                title
                slug
                links {
                    __typename
                    ... on ContentfulPageCustom {
                        slug
                        title
                        contentful_id
                        userSection
                    }
                    ... on ContentfulPageDirectoryOfServices {
                        id
                        title
                    }
                    ... on ContentfulPageDonation {
                        slug
                        title
                        contentful_id
                    }
                    ... on ContentfulPageGeneric {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGroup {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGuideline {
                        title
                        slug
                    }
                    ... on ContentfulPageWidgets {
                        title
                        slug
                        userSection
                    }
                }
            }
        }
    }
}
`